((
    ($) => {

        $('body').on('change', '.js-cat-items-src', function () {
            let _changed = $(this);
            let _value = _changed.val();
            let _uid = _changed.attr('data-id');
            let _ajaxUrl = _changed.attr('data-ajax-url');
            if (typeof _ajaxUrl === 'undefined' || typeof _uid === 'undefined' || window.ajaxIsSending) {
                return false;
            }
            let _target = $(`.js-cat-items-dist[data-id="${_uid}"]`);
            if (!_target.length) {
                return false;
            }
            _target.find('option').each(function () {
                if ($(this).val().length) {
                    $(this).remove();
                } else {
                    $(this).attr('data-title', $(this).text());
                    $(this).text('Loading...');
                }
            });
            if (!_value.length) {
                _target.find('option').each(function () {
                    if (!$(this).val().length) {
                        $(this).text($(this).attr('data-title'));
                        $(this).removeAttr('data-title');
                    }
                });
                return false;
            }
            $.ajax({
                url: _ajaxUrl,
                method: 'GET',
                dataType: 'json',
                data: {
                    cid: _value,
                },
                beforeSend: function () {
                    window.ajaxIsSending = true;
                    _target.attr('disabled', 'disabled');
                    _changed.attr('disabled', 'disabled');
                },
                success: function (response) {
                    if (typeof response.status !== 'undefined' && response.status && typeof response.items !== 'undefined' && response.items) {
                        $.each(response.items, function (_key, _value) {
                            let _option = $('<option>');
                            _option.attr('value', _key)
                            _option.text(_value);
                            _target.append(_option);
                        });
                    }
                },
                error: function (xhr) {

                },
                complete: function () {
                    window.ajaxIsSending = false;
                    _target.removeAttr('disabled');
                    _changed.removeAttr('disabled');
                    _target.find('option').each(function () {
                        if (!$(this).val().length) {
                            $(this).text($(this).attr('data-title'));
                            $(this).removeAttr('data-title');
                        }
                    });
                },
            });
        });

    }
)
(jQuery));